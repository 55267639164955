import React, { Component } from 'react';
import Slider from 'react-slick';
import events from '../../img/Cubs_News_Bullseye_Final.jpg';
import Markdown from 'react-markdown';
import Moment from 'moment';
import _ from 'underscore';
let dateFormat = require('dateformat');
let count = 0;

function SampleNextArrow(props) {

	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style,  color:'white' }}
			onClick={onClick}
		/>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick} = props; 
	return (
		<div
			className={className}
			style={{ ...style, color : 'white' }}
			onClick={onClick} 
		/>
	);
}

function Image(image){
	if (image.image.eventImage != null){
		return <img src={image.image.eventImage.url} alt="events"/>;
	}
	return <img src= {events} alt="events"/>; 
}

function addDays(date, days) {
	let result = new Date(date);
	result.setDate(result.getDate() + days);
	return Moment(result).format('YYYY-MM-DD[T]HH:mm:ss');
}

function QueryEvents(events){
	const settings = {
		dots: true,
		centerMode: true,
		infinite: true,
		speed: 500,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow/>,
		slidesToShow: 1,
		slidesToScroll: 1
	};
	let temp = events.events;
	//calculate current date
	let today = new Date();
	let compare = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0 );

	let length= temp.length;
	//this if statement prevents populating recurring events again on rerender
	if (count === 0){
		for (let i = 0; i < length; i++){
			// temp[i].eventDate = addDays(temp[i].eventDate, 1);
			if (temp[i].recurringDates != null){
				for (let j = 0; j < temp[i].recurringDates.length; j++){
					let x = {
						eventTitle: '',
						eventLocation: '',
						descriptionHeader: '',
						eventDate: '',
						eventDescription: '',
						eventImage: '',
						eventTime:'',
						recurringDates: []
					};
					x.eventDate = temp[i].recurringDates[j].split('-')[2] + '-' + temp[i].recurringDates[j].split('-')[0] + '-' + temp[i].recurringDates[j].split('-')[1];
					x.route = temp[i].route;
					x.descriptionHeader = temp[i].descriptionHeader;
					x.eventDescription = temp[i].eventDescription;
					x.eventImage = temp[i].eventImage;
					x.eventLocation = temp[i].eventLocation;
					x.eventTime = temp[i].eventTime;
					x.recurringDates = temp[i].recurringDates;
					x.eventTitle = temp[i].eventTitle;
					temp.push(x);
				}
			}
			count++;
		}
	}
	for (let i = 0; i < temp.length; i++){
		//temp[i].eventDate = new Date(temp[i].eventDate.toString().substring(6) +'-'+ temp[i].eventDate.toString().substring(0,5));
	}

	//console.log(temp);
	// temp = temp.filter(function(date){
	// 	//console.log(date.eventDate);
	// 	//console.log(compare);
	// 	if (compare <= date.eventDate) {
	// 		return true;
	// 	}
	// 	return false;
	// });
	//console.log(temp);
	// sort events by date
	temp = _.filter(temp, function(d){
		return Moment(compare) <= Moment(d.eventDate);
	});
	temp = _.sortBy(temp, function(d){
		return new Date(Moment(d.eventDate));
	});
	//temp.sort(function compare(a, b) {
	//	return a.eventDate - b.eventDate;
	//});
	if (temp.length < 4 ){
		let length = temp.length;
		if (length === 0)
			return (<Slider{...settings}><div>
				<span className="slide-tint"><Image image={events} /></span>
				<div className="slide-content">
					<a className = 'white' href= {'/events/'}><button type="submit" className="btn btn-primary btn-submit white" >Read More</button></a>
				</div>
			</div></Slider>);
		else if (length === 1)
			return (<Slider{...settings}><div>
				<span className="slide-tint"><Image image={temp[0]} /></span>
				<div className="slide-content">
					<h3>{temp[0].eventTitle}</h3>

					<span className="slide-date">{Moment(temp[0].eventDate,'YYYY-MM-DD').format('MMMM DD, YYYY')} </span>
					<Markdown>{temp[0].eventDescription.substring(0,100) + '...'}</Markdown>
					<a className = 'white' href= {'/events/'+  (temp[0].route) + '/' + temp[0].eventDate}><button type="submit" className="btn btn-primary btn-submit white" >Read More</button></a>
				</div>
			</div></Slider>);
		else if (length == 2)
			return (<Slider{...settings}><div>
				<span className="slide-tint"><Image image={temp[0]} /></span>
				<div className="slide-content">
					<h3>{temp[0].eventTitle}</h3>
					<span className="slide-date">{Moment(temp[0].eventDate,'YYYY-MM-DD').format('MMMM DD, YYYY')} </span>
					<Markdown>{temp[0].eventDescription.substring(0,100) + '...'}</Markdown>
					<a className = 'white' href= {'/events/'+ (temp[0].route) + '/' + temp[0].eventDate}><button type="submit" className="btn btn-primary btn-submit white" >Read More</button></a>
				</div>
			</div>
			<div>
				<span className="slide-tint"><Image image={temp[1]}/></span>
				<div className="slide-content">
					<h3>{temp[1].eventTitle}</h3>
					<span className="slide-date">{Moment(temp[1].eventDate,'YYYY-MM-DD').format('MMMM DD, YYYY')} </span>
					<Markdown>{temp[1].eventDescription.substring(0,100) + '...'}</Markdown>
					<a className = 'white' href= {'/events/'+ (temp[1].route) + '/' + temp[1].eventDate}><button type="submit" className="btn btn-primary btn-submit white" >Read More</button></a>
				</div>
			</div>
			</Slider>);
		else
			return (<Slider{...settings}><div>
				<span className="slide-tint"><Image image={temp[0]} /></span>
				<div className="slide-content">
					<h3>{temp[0].eventTitle}</h3>
					<span className="slide-date">{Moment(temp[0].eventDate,'YYYY-MM-DD').format('MMMM DD, YYYY')} </span>
					<Markdown>{temp[0].eventDescription.substring(0,100) + '...'}</Markdown>
					<a className = 'white' href= {'/events/'+ (temp[0].route) + '/' + temp[0].eventDate}><button type="submit" className="btn btn-primary btn-submit white" >Read More</button></a>
				</div>
			</div>
			<div>
				<span className="slide-tint"><Image image={temp[1]}/></span>
				<div className="slide-content">
					<h3>{temp[1].eventTitle}</h3>
					<span className="slide-date">{Moment(temp[1].eventDate,'YYYY-MM-DD').format('MMMM DD, YYYY')} </span>
					<Markdown>{temp[1].eventDescription.substring(0,100) + '...'}</Markdown>
					<a className = 'white' href= {'/events/' + (temp[1].route) + '/' + temp[1].eventDate}><button type="submit" className="btn btn-primary btn-submit white" >Read More</button></a>
				</div>
			</div>
			<div>
				<span className="slide-tint"><Image image={temp[2]}/></span>
				<div className="slide-content">
					<h3>{temp[2].eventTitle}</h3>
					<span className="slide-date">{Moment(temp[2].eventDate,'YYYY-MM-DD').format('MMMM DD, YYYY')} </span>
					<Markdown>{temp[2].eventDescription.substring(0,100) + '...'}</Markdown>
					<a className = 'white' href= {'/events/'+ (temp[2].route) + '/' + temp[2].eventDate}><button type="submit" className="btn btn-primary btn-submit white" >Read More</button></a>
				</div>
			</div>
			</Slider>);
	}
	
	else{
		return (<Slider{...settings}><div>
			<span className="slide-tint"><Image image={temp[0]} /></span>
			<div className="slide-content">
				<h3>{temp[0].eventTitle}</h3>
				<span className="slide-date">{Moment(temp[0].eventDate,'YYYY-MM-DD').format('MMMM DD, YYYY')} </span>
				<Markdown>{temp[0].eventDescription.substring(0,100) + '...'}</Markdown>
				<a className = 'white' href= {'/events/'+ (temp[0].route) + '/' + temp[0].eventDate}><button type="submit" className="btn btn-primary btn-submit white" >Read More</button></a>
			</div>
		</div>
		<div>
			<span className="slide-tint"><Image image={temp[1]}/></span>
			<div className="slide-content">
				<h3>{temp[1].eventTitle}</h3>
				<span className="slide-date">{Moment(temp[1].eventDate,'YYYY-MM-DD').format('MMMM DD, YYYY')} </span>
				<Markdown>{temp[1].eventDescription.substring(0,100) + '...'}</Markdown>
				<a className = 'white' href= {'/events/'+ (temp[1].route) + '/' + temp[1].eventDate}><button type="submit" className="btn btn-primary btn-submit white" >Read More</button></a>
			</div>
		</div>
		<div>
			<span className="slide-tint"><Image image={temp[2]}/></span>
			<div className="slide-content">
				<h3>{temp[2].eventTitle}</h3>
				<span className="slide-date">{Moment(temp[2].eventDate,'YYYY-MM-DD').format('MMMM DD, YYYY')} </span>
				<Markdown>{temp[2].eventDescription.substring(0,100) + '...'}</Markdown>
				<a className = 'white' href= {'/events/'+ (temp[2].route) + '/' + temp[2].eventDate}><button type="submit" className="btn btn-primary btn-submit white" >Read More</button></a>
			</div>
		</div>
		<div>
			<span className="slide-tint"><Image image={temp[3]} /></span>
			<div className="slide-content">
				<h3>{temp[3].eventTitle}</h3>
				<span className="slide-date">{Moment(temp[3].eventDate,'YYYY-MM-DD').format('MMMM DD, YYYY')} </span>
				<Markdown>{temp[3].eventDescription.substring(0,100) + '...'}</Markdown>
				<a className = 'white' href= {'/events/'+ (temp[3].route) + '/' + temp[3].eventDate}><button type="submit" className="btn btn-primary btn-submit white" >Read More</button></a>
			</div>
		</div></Slider>
		);
	}
}

class Events extends Component{

	render(){
		let events = this.props.events.props.homePageQuery.eventItems;
		return(
			<section className="section">
				<header className="section-header row ">
					<div className="col">
						<h2 className="section-title section-title-left">Events</h2>		
					</div>
				</header>
				<div className="slick-events row slick-initialized slick-slider">
					<QueryEvents events = {events}/>
				</div>
			</section>
		);
	}
}

export default Events;