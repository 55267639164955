import React, { Component } from 'react';
import everybodyIn from '../../img/Cubs_News_Bullseye_Final.jpg';
import ReactMarkdown from 'react-markdown';
import Moment from 'moment';
import _ from 'underscore';
let dateFormat = require('dateformat');

function addDays(date, days) {
	let result = new Date(date);
	result.setDate(result.getDate() + days);
	return Moment(result, 'DD-MM-YYYY').format('MM-DD-YYYY');
}

function QueryEvents(events){
	let temp = events.events.eventItems;

	//calculate current date
	let today = new Date();
	let compare = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0 );


	let length= temp.length;
	for (let i = 0; i < length; i++){
		// temp[i].eventDate = addDays(temp[i].eventDate, 1);
		if (temp[i].recurringDates != null){
			for (let j = 0; j < temp[i].recurringDates.length; j++){
				let x = {
					eventTitle: '',
					eventLocation: '',
					descriptionHeader: '',
					eventDate: '',
					eventDescription: '',
					eventImage: '',
					eventTime:'',
					recurringDates: []
				};
				x.eventDate = temp[i].recurringDates[j];
				x.descriptionHeader = temp[i].descriptionHeader;
				x.eventDescription = temp[i].eventDescription;
				x.eventImage = temp[i].eventImage;
				x.eventLocation = temp[i].eventLocation;
				x.eventTime = temp[i].eventTime;
				x.recurringDates = temp[i].recurringDates;
				x.eventTitle = temp[i].eventTitle;
				x.route = temp[i].route;
				temp.push(x);
			}
		}
	}
	for (let i = 0; i < temp.length; i++){
		//temp[i].eventDate = new Date(temp[i].eventDate.toString().substring(6) +'-'+ temp[i].eventDate.toString().substring(0,5));
	}
	temp = _.filter(temp, function(d){
		return Moment(compare) <= Moment(d.eventDate);
	});
	//temp = temp.filter(function(date){
	//	if (compare <= date.eventDate) {
	//		return true;
	//	}
	//	return false;
	//});
	// sort events by date
	//temp.sort(function compare(a, b) {
	//	return a.eventDate - b.eventDate;
	//});
	temp = _.sortBy(temp, function(d){
		return new Date(Moment(d.eventDate));
	});
	

	return  (<ul>	<div className= "events-section">
		<div className="section-content row cubs-events"> {temp.map(function(link,index)
		{
			if (temp[index].eventImage != null){
				return <li key={index}> 		
					<div className="col md-4 events flex-item">
						<a href={'/events/'+ (temp[index].route) + '/' + temp[index].eventDate}>
							<div className="events-img-container">
								<img src= {temp[index].eventImage.url} alt = "events"/>
							</div>
						</a>
						<h3>
							<a href={'/events/'+ (temp[index].route) + '/' + temp[index].eventDate}>
								{temp[index].eventTitle}
							</a>
						</h3>
						<p> {Moment(temp[index].eventDate).format('MM/DD/YYYY')}</p>
						<ReactMarkdown source={temp[index].eventDescription.substring(0,80) + '...'}/> <a href = {'/events/'+ (temp[index].route) + '/' + temp[index].eventDate}> Read More </a>
					</div>
				</li>;}
			return <li key={index}> 
				<div className="col md-4 events flex-item">
					<a href={'/events/'+ (temp[index].route) + '/' + temp[index].eventDate}>
						<div className="events-img-container">
							<img src= {everybodyIn} alt="events"/>
						</div>
					</a>
					<h3>
						<a href={'/events/'+ (temp[index].route) + '/' + temp[index].eventDate}>
							{temp[index].eventTitle}
						</a>
					</h3>
					<p> {Moment(temp[index].eventDate).format('MM/DD/YYYY')}</p>
					<ReactMarkdown source={temp[index].eventDescription.substring(0,80) + '...'}/><a href = {'/events/'+ (temp[index].route) + '/' + temp[index].eventDate}> Read More </a>
				</div>
			</li>;
		})}
		</div>
	</div></ul>);
				

}

class allEvents extends Component{

	render(){
		const events = this.props.events;
		return(
			<section className="section">
				<header className="section-header row">
					<div className="col">
						<h2 className="section-title"> {events.eventsPages[0].eventsHeader}</h2>
					</div>
				</header>
				<QueryEvents events={events}/>
			</section>
		);

	}
}

export default allEvents;