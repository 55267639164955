import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import everybodyIn from '../../img/Cubs_News_Bullseye_Final.jpg';
import ReactPlayer from 'react-player/file';
import Moment from 'moment';
let dateFormat = require('dateformat');

function Image(img){
	if (img.img.image != null){
		return (<img src= {img.img.image.url} alt = "display"/>);
	}
	if (img.img.videoLink != null){
		return <div/>;
	}
	return(<img src= {everybodyIn} alt ="generic"/>);
}

function Video(vid){
	if (vid.vid.videoLink == null){
		return <div/>;
	}
	// return <iframe title="vimeo-player" src={`https://player.vimeo.com/video/`+vid.vid.videoLink} width="640" height="360" frameBorder="0" allowFullScreen></iframe>;
	return <div className='player-wrapper'><ReactPlayer config={{ file: { attributes: { controlsList: 'nodownload' }}}} className='react-player' controls={true} muted={true} width="100%" height="100%" url={vid.vid.videoLink} /></div>
}

class newsTemplateContenful extends Component{

	render(){
		const news = this.props.news;
		console.log(news)
		let date = Moment(news.date).format('MMMM DD, YYYY');
		return(
			<section className="section">
				<div className="section-content row news-detail-intro">
					<div className="col md-12">
						<h1 className="black"> {news.newsTitle}</h1>
						<Image img={news}/>
					</div>
					<div className="col md-12">
						<br/>
						<div className="post-date black">
							{date}
						</div>
						<br/>
						<ReactMarkdown className = "black news-markdown" source= {news.description}/>
					</div>
					<Video vid = {news}/>
					<a href='/cubs-news'>
						<div> ALL NEWS </div>
					</a>
				</div>
			</section>
		);

	}
}

export default newsTemplateContenful;