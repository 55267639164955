import React, {Component} from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import PageTransition from './PageTransition';
import {SecureRoute, Security, ImplicitCallback, LoginCallback  } from '@okta/okta-react';
import { createBrowserHistory } from 'history';

import withTracker from './components/withTracker';

import Home from './pages/home/Home';
import homeWithData from './pages/home/homeWithData';

import genericBox from './pages/genericWithBoxes/generic';
import genericBoxWithData from './pages/genericWithBoxes/genericWithData';

import genericNews from './pages/genericwithnews/generic';
import genericNewsWithData from './pages/genericwithnews/genericWithData';

import list from './pages/genericList/list';
import listWithData from './pages/genericList/listWithData';

import news from './pages/news/news';
import newsPages from './pages/news/newsPages';
import newsPagesContentful from './pages/news/newsPagesContentful';
import newsPagesWithData from './pages/news/newsPagesWithData';
import newsWithData from './pages/news/newsWithData';
import newsTemplateWithData from './components/news/newsTemplateWithData';
import allNewsWithData from './components/news/newsTemplateWithData';

import events from './pages/events/events';
import eventsPages from './pages/events/eventsPages';
import eventsWithData from './pages/events/eventsWithData';
import eventPagesWithData from './pages/events/eventPagesWithData';

import executive from './pages/executive/executive';
import executiveWithData from './pages/executive/executiveWithData';

import search from './pages/search/search';
import searchWithData from './pages/search/searchWithData';

import calendar from './pages/calendar/calendar';
import calendarWithData from './pages/calendar/calendarWithData';

import gallery from './pages/gallery/gallery';
import galleryWithData from './pages/gallery/galleryWithData';

import signature from './pages/signature/signature';
import signatureWithData from './pages/signature/signatureWithData';

 
const history = createBrowserHistory();

class Router extends Component{
	constructor(props){
		super(props);
		this.state={
			'client_id': '',
			'issuer': '',
			'dataLoaded': false
		};
		this.componentDidMount = this.componentDidMount.bind(this);
	}

	componentDidMount(){
		fetch('/authenticate',{
			method : 'POST',
			headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }})
			.then(response => response.json())
			.then(authenticate => {
				this.setState({client_id: authenticate.client_id});
				this.setState({issuer: authenticate.issuer});
				this.setState({redirect_uri: window.location.origin + '/implicit/callback'});
				this.setState({dataLoaded: true});
			}
		);
	}

	render(){
		let dataLoaded = this.state.dataLoaded;
		if (!dataLoaded){
			return <div className = 'u-hidden'> Loading... </div>;
		}
		return(
			<PageTransition history={history}>
				<Switch >		
					<Security issuer = {this.state.issuer} client_id = {this.state.client_id} redirect_uri= {this.state.redirect_uri} history = {history}>
						<Route path="/implicit/callback" component={LoginCallback}/>
						<SecureRoute exact path="/" component={withRouter(homeWithData(allNewsWithData(Home)))}/>
						<SecureRoute exact path="/associate-roster" component={withRouter(listWithData(list))}/>
						<SecureRoute exact path="/calendar" component={withRouter(calendarWithData(calendar))}/>
						<SecureRoute exact path="/cubs-news" component={withRouter(newsWithData(news))}/>
						<SecureRoute exact path="/cubs-news/:news" component={withRouter(newsPagesWithData(newsTemplateWithData(newsPages)))}/>
						<SecureRoute exact path="/cubs-news-departments/:news" component={withRouter(newsPagesWithData(newsPagesContentful))}/>
						<SecureRoute exact path="/video-series" component={withRouter(galleryWithData(gallery))}/>
						<SecureRoute exact path="/departments" component={withRouter(genericBoxWithData(genericBox))}/>
						<SecureRoute exact path="/departments/:department" component={withRouter(genericNewsWithData(genericNews))}/>
						<SecureRoute exact path="/events" component={withRouter(eventsWithData(events))}/>
						<SecureRoute exact path="/events/:eventRoute/:eventDate" component={withRouter(eventPagesWithData(eventsPages))}/>
						<SecureRoute exact path="/executive-leadership" component={withRouter(executiveWithData(executive))}/>
						<SecureRoute exact path="/marketing-and-promotional-assets" component={withRouter(listWithData(list))}/>
						<SecureRoute exact path="/media-and-communication-materials" component={withRouter(listWithData(list))}/>
						<SecureRoute exact path="/policies-procedures-and-workflows" component={withRouter(listWithData(list))}/>
						<SecureRoute exact path="/resources" component={withRouter(genericBoxWithData(genericBox))}/>
						<SecureRoute exact path="/search-results" component={withRouter(searchWithData(search))}/>
						<SecureRoute exact path="/signature" component={withRouter(signatureWithData(signature))}/>
					</Security>		
				</Switch>
			</PageTransition>
		);}

	
}
export default Router;
