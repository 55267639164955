import React, { Component } from 'react';
import Moment from 'moment';
import ReactPlayer from 'react-player/file'
import VideoThumbnail from './VideoThumbnail'


function FeatureItem(featureItem) {

    let item = featureItem.featureItem[0];

		if(featureItem.featureItem.length === 0){
			return (
		        <div className="news-section">
		          <p>No items match the selected criteria.</p>
		        </div>
		      );
		} else{
			return (
		      <div>
		      <h3 className="section-title row news-layout">Featured</h3>
		        <div className="section-content row cubs-news">
		        <div id={item.name} className="col md-11 flex-item">
		        {item.file.url.includes('videos') ? 
	              		(item.showDetails ?
	              		<div className="news-img-container">
		              		<div className='player-wrapper'>
				                <ReactPlayer 
				                  	config={{ file: { attributes: { controlsList: 'nodownload' }}}}
				                  	id={item.name} 
				                  	className='react-player' 
				                  	onStart={() => window.dataLayer.push({'event': 'gallery_video_start', 'video_name': item.name })}
    								onEnded={() => window.dataLayer.push({'event': 'gallery_video_end', 'video_name': item.name })}
				                  	controls={true} 
				                  	light=<VideoThumbnail
									    videoUrl={item.file.url}
									    thumbnailHandler={(thumbnail) => (thumbnail)}
								    	snapshotAtTime={item.snapshotTime} 
								    /> 
								    muted={true} 
								    width="100%" 
								    height="100%"
								    url={item.file.url} />
							</div>
				            <h3><b>{item.name}</b></h3>
				            <p>{Moment.utc(item.releaseDate).format('MM/DD/YYYY')}</p>
				            <p>{item.description}</p>  
		                </div>:
		                <div className="news-img-container">
		              		<div className='player-wrapper'>
				                <ReactPlayer 
				                  	config={{ file: { attributes: { controlsList: 'nodownload' }}}}
				                  	id={item.name} 
				                  	className='react-player' 
				                  	onStart={() => window.dataLayer.push({'event': 'gallery_video_start', 'video_name': item.name })}
    								onEnded={() => window.dataLayer.push({'event': 'gallery_video_end', 'video_name': item.name })}
				                  	controls={true} 
				                  	light=<VideoThumbnail
									    videoUrl={item.file.url}
									    thumbnailHandler={(thumbnail) => (thumbnail)}
								    	snapshotAtTime={item.snapshotTime} 
								    /> 
								    muted={true} 
								    width="100%" 
								    height="100%"
								    url={item.file.url} />
							</div>
		                </div>
		                ):
		                (item.showDetails ?
		                	<div className="news-img-container">
		              		<div className='player-wrapper'>
								<img src= {item.file.url} alt="news"/>
							</div>
				            <h3><b>{item.name}</b></h3>
				            <p>{Moment.utc(item.releaseDate).format('MM/DD/YYYY')}</p>
				            <p>{item.description}</p>  
		                </div>:
		                <div className="news-img-container">
		              		<div className='player-wrapper'>
								<img src= {item.file.url} alt="news"/>
							</div>
		                </div>
		                )
		            	}
		         </div>
		        </div>
		      </div>
		    );
		}
}


export default FeatureItem;