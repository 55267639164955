import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function newsWithData(WrappedComponent, options = {}) {
	const HOC = class extends Component {
		constructor(props) {
			super(props);
			this.wrapped = graphql(gql `query galleryPageQuery {
					galleryPages {
					  backgroundHeader
					  backgroundDescription
					  route
					  galleryGroups{
					  	name
					  	description
					  	galleryItems{
					  		name
					  		description
					  		releaseDate
					  		featuredVideo
					  		snapshotTime
					  		showDetails
					  		file{
			                  title
			                  description
			                  url
			                  __typename
			                }
			            }
					  }
				    backgroundImage {
				      title
				      description
				      url
				    }
					}
				  feedbackComponents {
				    feedbackHeader
				    feedbackDescription
				    feedbackCtAtext
				    feedbackImage {
				      title
				      description
				      url
				    }
				  }
				  headerFooterComponents {
				    footerHeader
				    footerBody
				    copyrightHolder
				    primaryNavigationLinks{
				      route
				      text
				    }
				    footerLinks{
				      route
				      text
				    }
				    secondaryFooterLinks {
				      route
				      text
				    }
				    footerLogo {
				      title
				      description
				      url
				    }
				  }
				  exploreComponents {
					    isNotDisplayed
					    exploreTitle
					    exploreTile {
					    backgroundImage {
						        title
						        description
						        url
						      }
					      route
					      title
					      ctaText
					  }
				  }
				  globalSites{feedback {
						 feedbackHeader
						 feedbackDescription
						 feedbackCtAtext
					   feedbackImage {
						 title
						 description
						 url
						}
					}
				  mainNavigation {
						 footerHeader
						 footerBody
						 copyrightHolder
						 primaryNavigationLinks{
							 route
							 text
						 }
						 footerLinks{
						   route
						   text
						 }
						 secondaryFooterLinks {
						   route
						   text
						 }
						 footerLogo {
						   title
						   description
						   url
						 }
				   }
				   cubsLogo{
		              title
		              url
		            }
			  }
}
	  `, { name: 'galleryPageQuery' })(WrappedComponent);
		}

		render() {
			const Wrapped = this.wrapped;
			return <Wrapped {...this.props}/>;

		}
	};

	return HOC;
}